import React from 'react';
import {Dimensions, Image, StyleSheet, RefreshControl, Platform, TouchableOpacity} from 'react-native';
import Category from '../components/Category';
import {Text, Box, NavBar, Icon} from 'native-base';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import theme from '../theme';
import { connect } from 'react-redux';
import { loadCategories } from '../redux/actions/rogers';
import Alert from '../components/Alert';
const loadingImage = require('../assets/loader.gif');
const width = Dimensions.get('window').width;

class Categories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshing: false
    };
  }

  _onRefresh = () => {
    this.setState({refreshing: true});
    this.props.loadCategories().then(() => {
      this.setState({refreshing: false});
    });
  }

  componentDidMount() {
    if (this.props.categories.data===null) this.props.loadCategories();
  }

  render() {
    const { navigation } = this.props;

    return (
      <Box safe flex="1">
        <Alert></Alert>
        {this.props.categories.data===null ? <Box style={{ alignItems: 'center' }} ><Image source={loadingImage} /></Box>:
        <KeyboardAwareScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false} refreshControl={<RefreshControl refreshing={this.state.refreshing } onRefresh={this._onRefresh}/>}>
          <Box style={styles.container}>
            <Box flex="1" style={{ marginBottom: theme.SIZES.BASE }}>              
              <Box flex="1" center style={{ padding: theme.SIZES.BASE }}>                
                  {this.props.categories.data!==null && this.props.categories.data.map((c,key) => {
                    const {_id, name,limit,icon } = c;
                    return <Category key={key} id={_id} limit={limit} icon={icon} name={name} />
                  })}
              </Box>
            </Box>
          </Box>
        </KeyboardAwareScrollView>}
      </Box>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    marginTop:-12
  },
  button: {
    marginBottom: 20,
  },
  cards: {
    flex: 1,
    backgroundColor: theme.COLORS.WHITE,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  card: {
    borderWidth: 0,
    backgroundColor: theme.COLORS.WHITE,
    width: width - theme.SIZES.BASE * 2,
    marginVertical: theme.SIZES.BASE * 0.875,
  },
  cardFooter: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginVertical: theme.SIZES.BASE / 2,
    paddingHorizontal: theme.SIZES.BASE,
    paddingVertical: theme.SIZES.BASE / 2,
    backgroundColor: theme.COLORS.TRANSPARENT,
  },
  cardNoRadius: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  cardAvatar: {
    width: theme.SIZES.BASE * 2.5,
    height: theme.SIZES.BASE * 2.5,
    borderRadius: theme.SIZES.BASE * 1.25,
  },
  cardTitle: {
    justifyContent: 'center',
    paddingLeft: theme.SIZES.BASE / 2,
  },
  cardImageContainer: {
    borderWidth: 0,
    overflow: 'hidden',
  },
  cardImageRadius: {
    borderRadius: theme.SIZES.BASE * 0.1875,
  },
  cardImage: {
    width: 'auto',
    height: theme.SIZES.BASE * 12.5,
  },
  cardRounded: {
    borderRadius: theme.SIZES.BASE * 0.5,
  },
  cardFull: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
  },
  cardGradient: {
    bottom: 0,
    left: 0,
    right: 0,
    height: 90,
    position: 'absolute',
    overflow: 'hidden',
    borderBottomRightRadius: theme.SIZES.BASE * 0.5,
    borderBottomLeftRadius: theme.SIZES.BASE * 0.5,
  },
});


const mapStateToProps = (state) => ({
  categories: state.rogers.categories
})

const mapDispatchToProps = {
  loadCategories
}

export default connect(mapStateToProps, mapDispatchToProps)(Categories)