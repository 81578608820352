import React from 'react';
import { connect } from 'react-redux';
import {Text, Box} from 'native-base';
import theme from '../theme';
import {Dimensions} from 'react-native';
import MonthpickerWrapper from './MonthpickerWrapper';


const width = Dimensions.get('window').width;

const LogoTitle = props => {

  const limit = props.spendingCategory?.category?.limit;
  const total = Math.ceil(props.spendingCategory?.transactions?.total) || 0;

  return props.auth.clientID? <Box style={{flex:1, flexDirection: 'row', width:width-100, alignItems: 'center', justifyContent: 'space-between'}}>
  <Text style={{flex:1}} fontSize="20">{props.name || `${props.spendingCategory?.category?.name} (${total} / ${limit})`}</Text>
  <MonthpickerWrapper style={{flex:1}}/>
</Box> : <></>

}

const mapStateToProps = state => ({
  spendingCategory: state.rogers.spendingCategory,
  feed: state.rogers.feed,
  auth: state.auth
})

export default connect(mapStateToProps)(LogoTitle);