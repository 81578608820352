import React, {useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import theme from './../theme';
import SpendingCategory from './../screens/SpendingCategory';
import { reloadData } from '../redux/actions/rogers';
import Login from './../screens/Login';
import Spending from './../screens/Spending';
import Transactions from './../screens/Transactions';
import Logout from './../screens/Logout';
import Categories from './../screens/Categories';
import {AppState} from 'react-native';
import LogoTitle from './LogoTitle';
import * as Notifications from 'expo-notifications';
import { MaterialCommunityIcons } from '@expo/vector-icons';    
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import {
    createDrawerNavigator,
    DrawerContentScrollView,
  } from '@react-navigation/drawer';

import {
    NativeBaseProvider,
    Button,
    Box,
    HamburgerIcon,
    Pressable,
    Heading,
    VStack,
    Text,
    Center,
    HStack,
    Divider,
    Icon,
} from 'native-base';

const Stack = createNativeStackNavigator();

const Drawer = createDrawerNavigator();

function Component(props) {
  return (
    <Center>
      <Text mt="12" fontSize="18">
        This is {props.route.name} page.
      </Text>
    </Center>
  );
}

const getIcon = (screenName) => {
    switch (screenName) {
      case 'Spending':
        return 'cash-multiple';
      case 'Categories':
        return 'format-list-text';
      case 'Transactions':
        return 'cash-100';
      case 'Logout':
        return 'logout-variant';
      default:
        return undefined;
    }
  };

function MyDrawer() {
    return (
      <Box style={{height:10}} flex={1}>
        <Drawer.Navigator
          drawerContent={(props) => <CustomDrawerContent {...props} />}
        >
          <Drawer.Screen options={{ headerTitle: (props) => <LogoTitle name="Spending" /> }} name="Spending" component={Spending} />
          <Drawer.Screen name="Categories" component={Categories} />
          <Drawer.Screen options={{ headerTitle: (props) => <LogoTitle name="Transactions" /> }} name="Transactions" component={Transactions} />
          <Drawer.Screen options={{ headerTitle: (props) => <LogoTitle name="Loogut" /> }} name="Logout" component={Logout} />
        </Drawer.Navigator>
      </Box>
    );
}


function CustomDrawerContent(props) {
    return (
      <DrawerContentScrollView {...props}>
        <VStack space="6" my="2" mx="1">
          <Box px="4">
            <Text bold color="gray.700">
              Pelkonen
            </Text>
            <Text fontSize="14" mt="1" color="gray.500" fontWeight="500">
              Banking App
            </Text>
          </Box>
          <VStack divider={<Divider />} space="4">
            <VStack space="3">
              {props.state.routeNames.map((name, index) => (
                <Pressable key={index}
                  px="5"
                  py="3"
                  rounded="md"
                  bg={
                    index === props.state.index
                      ? 'rgba(6, 182, 212, 0.1)'
                      : 'transparent'
                  }
                  onPress={(event) => {
                    props.navigation.navigate(name);
                  }}
                >
                  <HStack space="7" alignItems="center">
                    <Icon
                      color={
                        index === props.state.index ? 'primary.500' : 'gray.500'
                      }
                      size="5"
                      as={<MaterialCommunityIcons name={getIcon(name)} />}
                    />
                    <Text
                      fontWeight="500"
                      color={
                        index === props.state.index ? 'primary.500' : 'gray.700'
                      }
                    >
                      {name}
                    </Text>
                  </HStack>
                </Pressable>
              ))}
            </VStack>
          </VStack>
        </VStack>
      </DrawerContentScrollView>
    );
  }
  

const MainStackNavigator = props => {
    const notificationListener = useRef();
    const appState = useRef(AppState.currentState);

    const loadData = async () => {
        await props.reloadData();
    }

    const _handleAppStateChange = nextAppState => {
        if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
          loadData();
        }
    
        appState.current = nextAppState;
      };

    useEffect(() => {
        notificationListener.current = Notifications.setNotificationHandler({
            handleNotification: async () => 
            {
                await props.reloadData();
                props.navigation.navigate('Transactions');
                return {
                    shouldShowAlert: true,
                    shouldPlaySound: true,
                    shouldSetBadge: false,
                }
            }
        });

        const subscription = AppState.addEventListener("change", _handleAppStateChange);
    
        return () => {
          Notifications.removeNotificationSubscription(notificationListener.current);
          subscription.remove();
        }
    }, []);

  return <Stack.Navigator initialRouteName="Home">
        <Stack.Screen options={{ headerTitle: (props) => <LogoTitle /> }} name="Home" component={Login} />
        <Stack.Screen options={{headerShown:false}} name="Drawer" component={MyDrawer} />
        <Stack.Screen options={{ headerTitle: (props) => <LogoTitle /> }} name="SpendingCategory" component={SpendingCategory} />
    </Stack.Navigator>
}

const mapDispatchToProps = {
    reloadData
  }


const mapStateToProps = state => ({
    alerts: state.alert
})

export default connect(mapStateToProps, mapDispatchToProps)(MainStackNavigator);