import * as types from '../actions/types';

const initialState = {
    clientID:0,
    token: '',
    expoPushToken:''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.LOGIN:
            return {...state, clientID:action.payload.clientID,  token:action.payload.token };
        case types.LOGOUT:
            return initialState
        default:
            return state;
    }
}