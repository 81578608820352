import React, { useState } from 'react';
import {StyleSheet,TouchableOpacity,Image,useWindowDimensions} from 'react-native';
import theme from '../theme';
import moment from 'moment';
import {Box, Text, Icon} from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';

function SpendingItem({ amount, merchant = '', datetime, icon, note = '', source = '', totalWidth, amountWidth, total, limit }) {
  const width  = useWindowDimensions().width-20;
  const [isOpen, toggleOpen] = useState(false);
  const [value, onChange] = useState(null);
  let color = theme.COLORS.BLACK;
  if (limit && total) color = total>limit ? theme.COLORS.SPENDING_HIGH : total<limit*0.8 ? theme.COLORS.SPENDING_LOW : theme.COLORS.SPENDING_MODERATE;

  const styles = StyleSheet.create({
    label: {color},
    border: {backgroundColor: color,height:4, marginTop:-4, width:Math.min(width*total/(limit?limit:0.01), width)},
    block: {width:width, height:theme.SIZES.TRANSACTION, marginTop:10,maxWidth:width, flexDirection: 'row'},
    details: {paddingLeft:10, flexDirection: 'row', paddingRight:30,flexShrink: 1,flexGrow: 1,fontSize:14, justifyContent:'space-between', backgroundColor:theme.COLORS.BACKGROUND},
    icon: {marginLeft:10, marginTop:10}
  });

  return (
    <Box>
      <Box style={styles.block}>
        <Box middle style={{ width:50, flexShrink:0}}>
          <Icon size={theme.SIZES.ICON} style={styles.icon} as={<MaterialCommunityIcons name={icon} />} color={theme.COLORS.ICON} />
        </Box>

        <Box style={styles.details}>
          <Box style={{ justifyContent: 'center', minWidth:amountWidth }}>
            {amount>0 && <Text>${amount}</Text>}
          </Box>      
          {<Box style={{paddingLeft:5,justifyContent: 'center', fontSize:14, flexShrink:1, flexGrow:1}}>
            <Text numberOfLines={1} style={{fontWeight: 'bold'}}>{merchant || note}</Text>
            {merchant!=='' && note!=='' && <Text style={{color:'#999'}} numberOfLines={1} muted>{note}</Text>}
          </Box>}
          {
            datetime!==undefined &&
            <Box style={{justifyContent: 'center', paddingLeft:5, marginRight:-20}}>
              <Text style={{ color: theme.COLORS.DATE, textAlign: 'right'}} numberOfLines={1} >{moment(datetime).format('h:mma')}</Text>
              <Text style={{ color: theme.COLORS.DATE, textAlign: 'right'}} numberOfLines={1} >{moment(datetime).format('MMM DD')}</Text>
            </Box>  
          }
        </Box>
        {<Box style={{ paddingLeft:5,justifyContent: 'center', minWidth:totalWidth, paddingRight:5}}>
          <Box>
            <Text style={{fontFamily:"roboto-medium", fontSize:20, color}}>${Math.ceil(total)}</Text>
          </Box>
        </Box>}
      </Box>
      {limit !==0 && <Box style={styles.border}></Box>}
    </Box>
  );
}

export default React.memo(SpendingItem);