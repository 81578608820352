import React from 'react';
import { connect } from 'react-redux';
import {Text, Box} from 'native-base';
import theme from '../theme';
import Monthpicker from './Montpicker';
import { loadFeed, loadTransactions } from '../redux/actions/rogers';

const MonthpickerWrapper = props => 
  <Monthpicker year={props.feed.year} month={props.feed.month} onUpdate={(year, month)=>{ props.loadFeed(year, month).then(() => {  
    props.loadTransactions(year, month);
    //this.setState({mLimitLoaded: this.props.feed.monthlyLimit, mLimit: this.props.feed.monthlyLimit })
  });
  }} />

const mapStateToProps = state => ({
    feed: state.rogers.feed
})

const mapDispatchToProps = {
  loadFeed,
  loadTransactions
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthpickerWrapper);