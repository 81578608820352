import React, {useState, useEffect, useCallback } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import MainStackNavigator from './components/MainStackNavigator';
import AppLoading  from 'expo-app-loading'
import * as Font from 'expo-font';
import { Provider } from 'react-redux';
import {getStore, getPersistor} from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import * as SplashScreen from 'expo-splash-screen';
import {
  NativeBaseProvider,
  Button,
  Box,
  HamburgerIcon,
  Pressable,
  Heading,
  VStack,
  Text,
  Center,
  HStack,
  Divider,
  Icon,
} from 'native-base';

//import { reloadData } from './redux/actions/rogers';

// const screens = {
//   Home: {
//     screen: Login
//   },

//   SpendingCategory: {
//     screen: SpendingCategory,
//     navigationOptions: ({navigation}) => {
//       return {
//           drawerLabel: () => null
//       }
//   }
//   }
// }


export default function App(props) {
  const [dataLoaded, setDataLoaded] = useState(false);
  const myStore = getStore();  
  const myPersistor = getPersistor();

  useEffect(() => {
    async function prepare() {
      try {
        // Pre-load fonts, make any API calls you need to do here
        await Font.loadAsync({
          'roboto-bold':{
               uri: require('./assets/fonts/Roboto-Bold.ttf'),
               display: Font.FontDisplay.FALLBACK
          },
          'roboto-regular':{
           uri: require('./assets/fonts/Roboto-Regular.ttf'),
           display: Font.FontDisplay.FALLBACK
           },
           'roboto-medium':{
             uri: require('./assets/fonts/Roboto-Medium.ttf'),
             display: Font.FontDisplay.FALLBACK
         }
        });
        // Artificially delay for two seconds to simulate a slow loading
        // experience. Please remove this if you copy and paste the code!
        await new Promise(resolve => setTimeout(resolve, 2000));
      } catch (e) {
        console.warn(e);
      } finally {
        // Tell the application to render
        setDataLoaded(true);
      }
    }
    prepare();
  }, []);

  const onLayoutRootView = useCallback(async () => {
    if (dataLoaded) {
      // This tells the splash screen to hide immediately! If we call this after
      // `setAppIsReady`, then we may see a blank screen while the app is
      // loading its initial state and rendering its first pixels. So instead,
      // we hide the splash screen once we know the root view has already
      // performed layout.
      await SplashScreen.hideAsync();
    }
  }, [dataLoaded]);

  if (!dataLoaded) {
    return null;
  }
  
  return (
    <Provider store={ myStore }>                
      <PersistGate persistor={myPersistor}>
        <NativeBaseProvider>
            <NavigationContainer>
              <MainStackNavigator />
          </NavigationContainer>
        </NativeBaseProvider>
      </PersistGate>
    </Provider>
  );
}
