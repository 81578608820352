import React from 'react';
import {logoutAction} from '../redux/actions/auth'
import { Box, Text} from 'native-base';
import theme from '../theme';
import { connect } from 'react-redux';

class Login extends React.Component {

  componentDidMount() {
    this.props.logoutAction(this.props.navigation)
  }
  
  render() {
    return (
      <Box safe flex="1" style={{ justifyContent: 'flex-start', backgroundColor: theme.COLORS.WHITE }}>
        <Text>Logging out...</Text>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

const mapDispatchToProps = {
  logoutAction
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)