import * as types from './types';
import api from '../../helpers/api';
import { setAlert } from './alert';
import store from '../store';
import moment from 'moment';
//Math.round(num * 100) / 100,

//Load Feed
export const loadFeed = (year, month) => async dispatch => {
    try {
        const data =(await api.get(`categories/transactions/${year}/${month}`)).data
        const credit =(await api.get(`balances/credit`)).data.amount;
        const debit =(await api.get(`balances/debit`)).data.amount;
        let monthlyLimit = (await api.get(`balances/limit${year}${month}`)).data;
        if (!monthlyLimit) monthlyLimit = {amount: data.reduce((acc,cur)=>{return cur.category.limit+acc;},0) }
        dispatch({type: types.FEED_LOADED, payload: {
            data, credit, debit, monthlyLimit:monthlyLimit.amount,
            monthlyTotal: Math.round(data.reduce((acc,cur)=>{return cur.category.limit>0 && cur.transactions?parseFloat(cur.transactions.total+acc):acc;},0) * 100) / 100,
            maxAmount: Math.round(Math.max(...data.map(c=>c.transactions?c.transactions.list[0].amount:0)) * 100) / 100,
            maxTotal:Math.round(Math.max(...data.map(c=>c.transactions?c.transactions.total:0)) * 100) / 100,
            year, month}});
    } catch (error) {
        dispatch({type: types.FEED_LOADED, payload: {data:[], year, month}});
        dispatch(setAlert(JSON.stringify(error), 'warning'));
    }
}

//Load Categories
export const loadCategories = () => async dispatch => {
    try {
        let data =(await api.get('categories')).data
        dispatch({type: types.CATEGORIES_LOADED, payload: data});
    } catch (error) {
        dispatch({type: types.CATEGORIES_LOADED, payload: []});
        dispatch(setAlert(JSON.stringify(error), 'warning'));
    }    
}

//Load Transactions
export const loadTransactions = (year, month) => async dispatch => {
    try {
        const data =(await api.get(`transactions/all/${year}/${month}`)).data;
        const maxAmount = Math.round(Math.max(...data.map(t=>t.amount)) * 100) / 100;
        dispatch({type: types.TRANSACTIONS_LOADED, payload: {data, maxAmount, year, month} });
    } catch (error) {
        dispatch({type: types.TRANSACTIONS_LOADED, payload: {data:[], year, month}});
        dispatch(setAlert(JSON.stringify(error), 'warning'));
    }    
}

//Search Transactions
export const searchTransactions = (substring, year, month) => async dispatch => {
    try {
        const data =(await api.get(`transactions/search/${substring}`)).data;
        const maxAmount = Math.round(Math.max(...data.map(t=>t.amount)) * 100) / 100;
        dispatch({type: types.TRANSACTIONS_LOADED, payload: {data, maxAmount, year, month} });
    } catch (error) {
        dispatch({type: types.TRANSACTIONS_LOADED, payload: {data:[], year, month}});
        dispatch(setAlert(JSON.stringify(error), 'warning'));
    }    
}

//Load Spending for a Category
export const selectSpendingCategory = (category) => async dispatch => {
    dispatch({type: types.SPENDING_CATEGORY_SELECT, payload:category });
}

//Update Transaction category
export const updateTransactionCategory = (id, oldCategory_id, category_id, saveForFuture = false) => async dispatch => {
    try {
        const updatedTransaction =(await api.patch(`transactions/${id}`, {category_id})).data;
        if (saveForFuture && updatedTransaction.merchantDetails) {
            await api.put(`merchants`, {name: updatedTransaction.merchantDetails.name, category_id})
            dispatch(setAlert(`Merchant ${updatedTransaction.merchantDetails.name} was linked to the ${updatedTransaction.categoryDetails.name} category for all future transactions`, 'success',5000));
        }
        dispatch( {type: types.TRANSACTION_UPDATED, payload: {oldCategory_id, updatedTransaction}} );
    } catch (error) {
        dispatch(setAlert(JSON.stringify(error), 'warning'));
    }    
}

const createFormData = (image) => {
    const DataURIToBlob = (dataURI: string) => {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
    }

    const data = new FormData();

    if (image.uri.includes("data:image/jpeg")) {
        console.log("base64 detected");
        const file = DataURIToBlob(image.uri);
        data.append('image', file);
    } else {
        data.append('image', {
            name: 'image',
            type: image.type,
            uri: image.uri.replace('file://', '')
        });
    }
    
    return data;
};

//Upload Transaction receipt image
export const uploadTransactionReceiptImage = (id, image) => async dispatch => {
    try {
        const updatedTransaction = (await api.post(`transactions/${id}/receipt`, createFormData(image), { headers: { 'Content-Type': 'multipart/form-data' } })).data;        
        dispatch( {type: types.TRANSACTION_UPDATED, payload: {oldCategory_id:updatedTransaction.category_id, updatedTransaction}} );
    } catch (error) {
        dispatch(setAlert(JSON.stringify(error), 'warning'));
    }    
}

//Delete Transaction receipt image
export const deleteTransactionReceiptImage = (id, image) => async dispatch => {
    try {
        const updatedTransaction = (await api.delete(`transactions/${id}/receipt/${image}`)).data;        
        dispatch( {type: types.TRANSACTION_UPDATED, payload: {oldCategory_id:updatedTransaction.category_id, updatedTransaction}} );
    } catch (error) {
        console.log(error);
        dispatch(setAlert(JSON.stringify(error), 'warning'));
    }    
}


//Update Transaction note
export const updateTransactionNote = (id, note ) => async dispatch => {
    try {
        const updatedTransaction =(await api.patch(`transactions/${id}`, {note})).data;
        dispatch( {type: types.TRANSACTION_UPDATED, payload: {oldCategory_id:updatedTransaction.category_id, updatedTransaction}} );
    } catch (error) {
        dispatch(setAlert(JSON.stringify(error), 'warning'));
    }    
}

//Update Transaction items
export const updateTransactionItems = (id, items ) => async dispatch => {
    try {
        const updatedTransaction =(await api.patch(`transactions/${id}`, {items})).data;
        dispatch( {type: types.TRANSACTION_UPDATED, payload: {oldCategory_id:updatedTransaction.category_id, updatedTransaction}} );
    } catch (error) {
        dispatch(setAlert(JSON.stringify(error), 'warning'));
    }    
}

export const reloadData = ()  => async dispatch => {
    const rogersStrore = store.getState().rogers;
    await dispatch(loadCategories());
    await dispatch(loadFeed(rogersStrore.feed.year, rogersStrore.feed.month));
    await dispatch(loadTransactions(rogersStrore.transactions.year, rogersStrore.transactions.month));
}


//Split Transaction
export const splitTransaction = (id, categories ) => async dispatch => {
    try {
        await api.post(`transactions/${id}/split`, categories);
        await dispatch(reloadData());
    } catch (error) {
        dispatch(setAlert(JSON.stringify(error), 'warning'));
    }
}

//Add Transaction
export const addTransaction = (category_id, amount, note ) => async dispatch => {
    try {
        await api.post(`transactions/`, {source:'cash', category_id, amount, note });
        await dispatch(reloadData());
    } catch (error) {
        dispatch(setAlert(JSON.stringify(error), 'warning'));
    }
}

//Delete Transaction
export const deleteTransaction = (id) => async dispatch => {
    try {
        await api.delete(`transactions/${id}`);
        await dispatch(reloadData());
    } catch (error) {
        dispatch(setAlert(JSON.stringify(error), 'warning'));
    }
}

//Update category limit
export const updateCategory = (id, limit) => async dispatch => {
    try {
        const updatedCategory =(await api.patch(`categories/${id}`, {limit})).data;
        dispatch( {type: types.CATEGORY_UPDATED, payload: updatedCategory} );
    } catch (error) {
        dispatch(setAlert(JSON.stringify(error), 'warning'));
    }    
}

//Update monthly allowance
export const updateAllowance = (year, month, allowance) => async dispatch => {
    try {
        const updatedAllowance =(await api.put(`balances/`, {key:`limit${year}${month}`, amount: allowance})).data;
        dispatch( {type: types.ALLOWANCE_UPDATED, payload: updatedAllowance} );
    } catch (error) {
        dispatch(setAlert(JSON.stringify(error), 'warning'));
    }    
}