import { combineReducers } from 'redux';
import alert from './alert';
import modal from './modal';
import rogers from './rogers';
import auth from './auth';

const appReducer = combineReducers({
    alert, modal, rogers, auth
})

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export default rootReducer