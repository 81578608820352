import React from 'react';
import moment from 'moment';
import {Dimensions, StyleSheet, Platform, TouchableOpacity} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import {Text, Box, NavBar, Icon} from 'native-base';
import theme from '../theme';
import { connect } from 'react-redux';
import Alert from '../components/Alert';
import Transaction from '../components/Transaction';
const width = Dimensions.get('window').width;

class SpendingCategory extends React.Component {
  
  componentDidMount() {
    if (!this.props.spendingCategory.transactions.list.length) this.props.navigation.navigate('Spending');
    //this.props.navigation.setOptions({ title: this.props.spendingCategory?.category?.name })
  }

  render() {
    const { navigation } = this.props
    const datetime = moment(`${this.props.feed.year}-${this.props.feed.month}-01`)
    const month = moment(datetime).format('MMMM')+(moment(datetime).format('YYYY') ===moment().format('YYYY')?'':' '+moment(datetime).format('YYYY') )
    const category = this.props.spendingCategory.category
    const transactions = this.props.spendingCategory.transactions
    const limit = category.limit
    const total =Math.ceil(transactions?.total)

    let color = theme.COLORS.BLACK;
    if (limit && total) color = total>limit ? theme.COLORS.SPENDING_HIGH : total<limit*0.8 ? theme.COLORS.SPENDING_LOW : theme.COLORS.SPENDING_MODERATE;
  
    const styles = StyleSheet.create({
      label: {color},
      container: {
        justifyContent: 'flex-start',
        marginTop:-12
      },
      border: {backgroundColor: color,height:4, marginTop:-4, width:Math.min(width*total/(limit?limit:0.01), width)}
    });
    
    return (
      <Box safe flex="1">
        {limit !==0 && <Box style={styles.border}></Box>}
        <Alert></Alert>
          <KeyboardAwareScrollView keyboardShouldPersistTaps={'always'} style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
            <Box style={styles.container}>
              <Box flex="1" style={{ marginBottom: theme.SIZES.BASE }}>              
                <Box flex="1" center style={{ padding: theme.SIZES.BASE }}>                
                    {transactions?.list.map((t,key) => {
                      const {_id, note, items, receiptPhotos, source, amount, merchantDetails, datetime, category_id } = t;
                      const maxAmount =  Math.round(Math.max(...transactions.list.map(t=>t.amount)) * 100) / 100;
                      return <Transaction key={key} id={_id} category_id={category_id} amountWidth={maxAmount.toString().length*6+20+16} note={note} items={items} receiptPhotos={receiptPhotos} source={source} amount={amount} merchant={merchantDetails?merchantDetails.name:''} datetime={datetime} />
                    })}
                </Box>
              </Box>
            </Box>
          </KeyboardAwareScrollView>
      </Box>
    );
  }
}


const mapStateToProps = (state) => ({
  spendingCategory: state.rogers.spendingCategory,
  feed: state.rogers.feed
})

export default connect(mapStateToProps, {})(SpendingCategory)