import React from 'react';
import {loginAction} from '../redux/actions/auth'
import { Dimensions, KeyboardAvoidingView, StyleSheet, Platform, } from 'react-native';
import Alert from '../components/Alert';
import { Box, Button, Input, NavBar, Text, } from 'native-base';
import theme from '../theme';
import { connect } from 'react-redux';
const { height, width } = Dimensions.get('window');

class Login extends React.Component {
  state = {
    clientID: '',
    password: '',
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  }

  componentDidMount() {
    if (this.props.auth.clientID) this.props.navigation.navigate('Drawer'); // Spending // Categories // Transactions // Profile
  }
  
  render() {
    const { navigation } = this.props;
    const { clientID, password } = this.state;

    return (
      <Box safe flex="1" style={{ justifyContent: 'flex-start', backgroundColor: theme.COLORS.WHITE }}>
        <KeyboardAvoidingView style={styles.container} behavior="height" enabled>
          <Box center space="evenly">
            <Alert></Alert>
            <Box>
              <Input placeholder="User ID" autoCapitalize="none" style={{ width: width * 0.9 }} onChangeText={text => this.handleChange('clientID', text)} />
              <Input password viewPass placeholder="Password" style={{ width: width * 0.9}} onChangeText={text => this.handleChange('password', text)} />
            </Box>
            <Box style={{marginTop:20}}>
              <Button round color={theme.COLORS.PRIMARY} onPress={()=>this.props.loginAction(clientID, password, navigation)} > Sign in </Button>
            </Box>
          </Box>
        </KeyboardAvoidingView>
      </Box>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingTop: theme.SIZES.BASE * 0.3,
    paddingHorizontal: theme.SIZES.BASE,
    backgroundColor: theme.COLORS.WHITE,
  }
});

const mapStateToProps = (state) => ({
  auth: state.auth
})

const mapDispatchToProps = {
  loginAction
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)