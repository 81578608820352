import React from 'react';
import { connect } from 'react-redux';
import {Text, Box} from 'native-base';
import theme from './../theme';
const Alert = props => props.alerts !== null && props.alerts.length > 0 && props.alerts.map(alert => (
  <Box style={{backgroundColor:alert.alertType==='warning'?theme.COLORS.PRIMARY:theme.COLORS.SUCCESS ,padding:10,margin:10}} key={alert.id}><Text style={{color:'#FFFFFF'}}>{alert.msg}</Text></Box>
))

const mapStateToProps = state => ({
    alerts: state.alert
})

export default connect(mapStateToProps)(Alert);