import * as types from '../actions/types';
const initialState = {
    content: '',
    title: 'Info',
    signature:false,
    saveButton: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.SET_MODAL:
            return action.payload ? { ...action.payload, active: true } : { ...initialState, active: true };
        case types.REMOVE_MODAL:
            return action.payload ? { ...action.payload, active: false } : { ...state, active: false };
        default:
            return state;
    }
}
