import React, { useState } from 'react';
import { connect } from 'react-redux';
import {StyleSheet,TouchableOpacity,Image,Dimensions} from 'react-native';
import {Box, Text, Icon, Input, Button } from 'native-base';
import { updateCategory } from '../redux/actions/rogers';
import theme from '../theme';
import moment from 'moment';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const { width } = Dimensions.get('window')-30;

function Category({ id, name, limit, icon, updateCategory }) {

  const [isOpen, toggleOpen] = useState(false);
  const [cLimit, setcLimit] = useState( parseInt(limit));

  const toggleCategory = () => {
    toggleOpen(!isOpen);
  }

  const styles = StyleSheet.create({
    label: {color:theme.COLORS.BLACK},
    block: {width:width, height:theme.SIZES.TRANSACTION, marginTop:10,maxWidth:width, flexDirection: 'row'},
    details: {paddingLeft:10, flexDirection: 'row', paddingRight:10,flexShrink: 1,flexGrow: 1,fontSize:14, justifyContent:'space-between', backgroundColor:theme.COLORS.BACKGROUND},
    icon: {marginLeft:10, marginTop:10}
  });

  return (
    <Box>
      <TouchableOpacity onPress={ ()=>{toggleCategory();}}>
        <Box style={styles.block}>
          <Box middle style={{ width:50, flexShrink:0}}>
            <Icon size={theme.SIZES.ICON} style={styles.icon}  as={<MaterialCommunityIcons name={icon} />} color={theme.COLORS.ICON} />
          </Box>

          <Box style={styles.details}>
            <Box style={{paddingLeft:5,justifyContent: 'center', fontSize:14, flexShrink:1, flexGrow:1}}>
              <Text numberOfLines={1} style={{fontWeight: 'bold'}}>{name}</Text>
            </Box>
            {limit>0 &&
            <Box style={{justifyContent: 'center'}}>
              <Text muted>Monthly limit of ${limit}</Text>
            </Box>}
          </Box>
        </Box>
      </TouchableOpacity>

      {isOpen && <Box style={{paddingRight:10, paddingLeft:10, flexShrink:1, marginTop:10, alignItems: 'center', justifyContent:'center', flexDirection: 'row'}}>
          <Text>Monthly limit:</Text>
          <Input type="number-pad" style={{width:100, marginLeft:5, marginRight:5}} placeholder="monthly limit" value={cLimit.toString()}  onChangeText={(value) => setcLimit(value)}  />          
          <Icon onPress={()=>{ updateCategory(id,cLimit); toggleCategory(); }} size={5} style={styles.icon} as={<MaterialCommunityIcons name="content-save" />}  color={theme.COLORS.ICON} />
        </Box>}
    </Box>
  );
}



const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
  updateCategory
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Category))