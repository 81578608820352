import * as types from '../actions/types';
import moment from 'moment';

const initialState = {
    feed: {month:moment().format('MM'), year:moment().format('YYYY'), maxAmount:0, maxTotal:0, monthlyTotal:0, monthlyLimit:0, credit: 0,debit: 0, data: null},
    categories: {data: null},
    transactions: { maxAmount:0, month:moment().format('MM'), year:moment().format('YYYY'), data: null},
    spendingCategory: null
};

const updateFeedTotals = (feed) => {
    feed.monthlyTotal = Math.round(feed.data.reduce((acc,cur)=>{return cur.category.limit>0 && cur.transactions?parseFloat(cur.transactions.total+acc):acc;},0) * 100) / 100;
    feed.maxAmount = Math.round(Math.max(...feed.data.map(c=>c.transactions && c.transactions.list.length?c.transactions.list[0].amount:0)) * 100) / 100;
    feed.maxTotal = Math.round(Math.max(...feed.data.map(c=>c.transactions && c.transactions.list.length?c.transactions.total:0)) * 100) / 100;
}

export default function (state = initialState, action) {
    let feed = initialState.feed;
    switch (action.type) {
        case types.FEED_LOADED:
            return {...state, feed:{...action.payload}, spendingCategory:state.spendingCategory?action.payload.data.find(c=>c.category._id===state.spendingCategory.category._id):null};
        case types.CATEGORIES_LOADED:
            return {...state, categories:{data: action.payload}};
        case types.TRANSACTIONS_LOADED:
            return {...state, transactions:{...action.payload}};
        case types.SPENDING_CATEGORY_SELECT:
            return {...state, spendingCategory:action.payload};
        case types.ALLOWANCE_UPDATED:
            return {...state, feed:{...state.feed, monthlyLimit: action.payload.amount} }
        case types.CATEGORY_UPDATED: 
            const updatedCategory = action.payload;
            const {_id, limit} = updatedCategory;
            if (state.feed.data!==null) {
                feed = {...state.feed, data:state.feed.data.map(c=>c.category._id===_id?{...c, category:{...c.category, limit:limit}}:c)}
                updateFeedTotals(feed)
            }
            return {
                ...state,
                feed: feed,
                categories: {...state.categories, data: state.categories.data.map(c=>c._id===_id?updatedCategory:c)}
            };
        case types.TRANSACTION_UPDATED: 
            const {updatedTransaction, oldCategory_id} = action.payload;
            let newCategory =  state.feed.data.find(c=>c.category._id===updatedTransaction.category_id);
            let oldCategory =  state.feed.data.find(c=>c.category._id===oldCategory_id);
            if (!newCategory.transactions) newCategory.transactions = {list:[]}
            if (!oldCategory.transactions) oldCategory.transactions = {list:[]}

            if (updatedTransaction.category_id!==oldCategory_id) {
                newCategory = {
                    ...newCategory,
                    transactions: {...newCategory.transactions, 
                        list: [...newCategory.transactions.list, updatedTransaction ].sort((a,b)=>{return moment(b.datetime) - moment(a.datetime)})
                    }
                }
                newCategory.transactions.total = newCategory.transactions.list.reduce((acc,cur)=>acc+cur.amount,0);
                
                oldCategory = {
                    ...oldCategory,
                    transactions: {...oldCategory.transactions, 
                    list: oldCategory.transactions.list
                        .filter(t=> t._id!==updatedTransaction._id)
                    }
                }
                oldCategory.transactions.total = oldCategory.transactions.list.reduce((acc,cur)=>acc+cur.amount,0);
            } else {
                oldCategory = {
                    ...oldCategory,
                    transactions: {...oldCategory.transactions, 
                        list: oldCategory.transactions.list.map(t=>t._id===updatedTransaction._id?updatedTransaction:t)
                    }
                }
                oldCategory.transactions.total = oldCategory.transactions.list.reduce((acc,cur)=>acc+cur.amount,0);
            }

            if (state.feed.data!==null) {
                feed = {
                    ...state.feed, data: state.feed.data
                    .map(c=> {
                        if (c.category._id===updatedTransaction.category_id  && updatedTransaction.category_id!==oldCategory_id ) return newCategory;
                        if (c.category._id===oldCategory_id) return oldCategory;
                        return c;
                    })
                    .sort((a,b)=>{
                        const date1 = a.transactions && a.transactions.list.length?a.transactions.list[0].datetime:0;
                        const date2 = b.transactions && b.transactions.list.length?b.transactions.list[0].datetime:0;
                        return moment(date2) - moment(date1)
                    })
                }
                updateFeedTotals(feed);
            }

            return {
                ...state,
                feed: feed,
                transactions: state.transactions.data!==null ? {...state.transactions, data: state.transactions.data.map(t=>updatedTransaction._id===t._id?updatedTransaction:t)}:state.transactions.data,
                spendingCategory: oldCategory
            };
        default:
            return state;
    }
}
