import React from 'react';
import moment from 'moment';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import {Image, StyleSheet,  RefreshControl, Platform, TouchableOpacity, Keyboard} from 'react-native';
import MonthPicker from '../components/Montpicker';
import Transaction from '../components/Transaction';
import {Text, View, Box, NavBar,Button, Input, Icon} from 'native-base';
import theme from '../theme';
import { connect } from 'react-redux';
import { loadTransactions, searchTransactions, addTransaction } from '../redux/actions/rogers';
import Alert from '../components/Alert';
const loadingImage = require('../assets/loader.gif');
import { MaterialCommunityIcons } from '@expo/vector-icons';

class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addTransaction:false,
      searchMode:false,
      tSearch:'',
      transactionCategory:this.props.categories?this.props.categories[0]:null,
      transactionNote: '',
      transactionAmount:0,
      refreshing: false
    };
  }

  componentDidUpdate() {
    if (this.state.transactionCategory===null && this.props.categories!==null) this.setState({ transactionCategory: this.props.categories[0]});
  }

  _onRefresh = () => {
    this.setState({refreshing: true});
    this.props.loadTransactions(this.props.transactions.year,this.props.transactions.month).then(() => {
      this.setState({refreshing: false, addTransaction:false});
    });
  }

  searchKeyDown = (e) =>  {
    console.log(e.nativeEvent.key);
    if(e.nativeEvent.key == "Enter"){
        this.searchTransactions();        
    }
  }

  searchTransactions = () =>  {
    Keyboard.dismiss();this.state.tSearch?this.props.searchTransactions(this.state.tSearch, this.props.transactions.year,this.props.transactions.month):this.props.loadTransactions(this.props.transactions.year,this.props.transactions.month)
  }

  render() {
    const { navigation } = this.props;

    return (
      <Box safe flex="1">
        <View style={{alignItems: 'flex-end'}}>
          <TouchableOpacity style={{marginTop: 10, marginRight: 10}} onPress={() => { if (this.state.searchMode) {this.props.loadTransactions(this.props.transactions.year,this.props.transactions.month);}  this.setState({searchMode: !this.state.searchMode}) }}><Icon as={<MaterialCommunityIcons name={"magnify"} />}  size={5} color={theme.COLORS.ICON} /></TouchableOpacity>
        </View>
        
        <Alert></Alert>
        {this.state.searchMode && 
          <Box style={{paddingRight:10, paddingLeft:10, flexShrink:1, marginTop:10, alignItems: 'center', justifyContent:'center', flexDirection: 'row'}}>
            <Box style={{marginRight:40, flexShrink:1, flexGrow:1}} ><Input style={{marginLeft:20, marginRight:20}} onSubmitEditing={()=>{this.searchTransactions();}} placeholder="Search" value={this.state.tSearch} onChangeText={(value) => this.setState( {tSearch: value})}  /></Box>
            <Button style={{ width: 100 }} round onPress={()=>{this.searchTransactions()}} > Search </Button>
          </Box>

        }

        {this.props.transactions.data===null ? <Box style={{ alignItems: 'center' }} ><Image source={loadingImage} /></Box>:
        <KeyboardAwareScrollView keyboardShouldPersistTaps={'always'} style={{ flex: 1 }} showsVerticalScrollIndicator={false} refreshControl={<RefreshControl refreshing={this.state.refreshing } onRefresh={this._onRefresh}/>}>
          <Box style={styles.container}>
            <Box flex="1" style={{ marginBottom: theme.SIZES.BASE }}>              
              <Box flex="1" center style={{ padding: theme.SIZES.BASE }}>                
                  {this.props.transactions.data!==null && this.props.transactions.data.map((t,key) => {
                    const {_id, note, items, receiptPhotos, source, amount, merchantDetails, datetime, categoryDetails, filteredItems } = t;
                    return <Transaction key={key} id={_id} category_id={categoryDetails._id} icon={categoryDetails.icon} amountWidth={this.props.transactions.maxAmount.toString().length*6+20+16} note={note} items={items} receiptPhotos={receiptPhotos} source={source} amount={amount} merchant={merchantDetails?merchantDetails.name:''} datetime={datetime} filteredItems={filteredItems} />
                  })}
              </Box>
            </Box>
          </Box>
          <Box style={{alignItems: 'center' , marginTop:-10}}>
            {this.state.addTransaction &&
              <Box style={{paddingRight:10, paddingLeft:10, flexShrink:1, marginTop:10, alignItems: 'center', justifyContent:'center', flexDirection: 'row'}}>                
                  <Box>
                      <Icon onPress={
                        ()=>{
                          let catIndex = this.props.categories.indexOf(this.state.transactionCategory);
                          catIndex++; if (catIndex>=this.props.categories.length) catIndex = 0;
                          this.setState({transactionCategory:this.props.categories[catIndex]});
                        }
                      } size={theme.SIZES.ICON} as={<MaterialCommunityIcons name={this.state.transactionCategory.icon} />}  color={theme.COLORS.ICON} />
                  </Box>            
                  <Box style={{flex: 1,paddingLeft:10,paddingRight:10}}><Input type="number-pad" placeholder="amount" value={this.state.transactionAmount.toString()}  onChangeText={(value) => this.setState({transactionAmount:value})}  /></Box>
                  <Box style={{flex: 1,paddingLeft:10,paddingRight:10}}><Input placeholder="note" value={this.state.transactionNote.toString()}  onChangeText={(value) => this.setState({transactionNote:value})}  /></Box>
              </Box>}
            {!this.state.searchMode && this.props.transactions.year === moment().format('YYYY') && this.props.transactions.month === moment().format('MM') && 
            <Button shadowless round style={{marginTop:15, marginBottom:10}} color={theme.COLORS.SUCCESS} onPress={()=>{
                if (!this.state.addTransaction) {
                  this.setState({addTransaction: true});
                } else {
                  if (this.state.transactionAmount) this.props.addTransaction(this.state.transactionCategory._id, this.state.transactionAmount, this.state.transactionNote);
                  this.setState({transactionNote:''});
                  this.setState({transactionAmount:0});
                  this.setState({addTransaction: false});
                }
              }} >
              {this.state.addTransaction?'Save transaction':'Add Transaction'}
            </Button>}
          </Box>
        </KeyboardAwareScrollView>}
      </Box>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    marginTop:-12
  }
});

const mapStateToProps = (state) => ({
  categories: state.rogers.categories.data,
  feed: state.rogers.feed,
  transactions: state.rogers.transactions
})

const mapDispatchToProps = {
  loadTransactions,
  searchTransactions,
  addTransaction
}

export default connect(mapStateToProps, mapDispatchToProps)(Transactions)