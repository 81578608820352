import * as types from './types';

export const setAlert = (msg, alertType, timeout = 3000) => dispatch => {
    const id = Math.floor(Math.random() *  100000000000);
    dispatch({
        type: types.SET_ALERT,
        payload: { msg, alertType, timeout, id }
    });

    setTimeout(() => dispatch({ type: types.REMOVE_ALERT, payload: id }), timeout)
}