//Rogers
export const FEED_LOADED = 'FEED_LOADED';
export const CATEGORIES_LOADED = 'CATEGORIES_LOADED';
export const TRANSACTIONS_LOADED = 'TRANSACTIONS_LOADED';
export const TRANSACTION_UPDATED = 'TRANSACTION_UPDATED';
export const CATEGORY_UPDATED = 'CATEGORY_UPDATED';
export const SPENDING_CATEGORY_SELECT = 'SPENDING_CATEGORY_SELECT';
export const ALLOWANCE_UPDATED = 'ALLOWANCE_UPDATED';

//login, logout
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

//alerts, modals
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SET_MODAL = 'SET_MODAL';
export const REMOVE_MODAL = 'REMOVE_MODAL';