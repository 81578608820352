import React from "react";
import { connect } from 'react-redux';
import { loadFeed, reloadData, selectSpendingCategory, updateAllowance } from '../redux/actions/rogers';
import GestureRecognizer, {swipeDirections} from 'react-native-swipe-gestures';
import theme from '../theme';
import { Container, List, Input } from 'native-base';
import SpendingItem from '../components/SpendingItem';
import Alert from '../components/Alert';
import moment from 'moment';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {Dimensions, Image, StyleSheet, ScrollView, RefreshControl, Platform, TouchableOpacity, SafeAreaView} from 'react-native';
const width = Dimensions.get('window').width;

const loadingImage = require('../assets/loader.gif');

import {
  NativeBaseProvider,
  Button,
  Box,
  HamburgerIcon,
  Pressable,
  Heading,
  VStack,
  Text,
  Center,
  HStack,
  Divider,
  Icon,
} from 'native-base';

class Spending extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshing: false,
      isOpen:false,
      displayCredit: true
    };
  }

  onSwipe(gestureName, gestureState) {
    const {SWIPE_LEFT, SWIPE_RIGHT} = swipeDirections;
    switch (gestureName) {
      case SWIPE_LEFT:
      case SWIPE_RIGHT:
        this.setState({displayCredit: !this.state.displayCredit});
      break;
      default:
      break;
    }
  }

  _onRefresh = () => {
    this.setState({refreshing: true});
    this.props.loadFeed(this.props.feed.year,this.props.feed.month).then(() => {     
      this.setState({refreshing: false });
    });
  }

  toggleAllowance = () => {
    this.setState({isOpen:!this.state.isOpen})
  }

  componentDidUpdate = () => {
    //if (this.props.feed.data!==null && this.state.mLimitLoaded===null) this.setState({mLimit: this.props.feed.monthlyLimit, mLimitLoaded: this.props.feed.monthlyLimit });
  }

  componentDidMount() {
    this.setState({refreshing: true});
    this.props.reloadData();
    this.props.loadFeed(this.props.feed.year,this.props.feed.month).then(() => {      
      this.setState({refreshing: false });
    });
  }

  loadCategory = async (c) => {
    if (c.transactions) {
      await this.props.selectSpendingCategory(c);
      this.props.navigation.navigate('SpendingCategory');
    }
  }

  render() {
    const { navigation } = this.props;
    const swipeConfig = {velocityThreshold: 1.2,directionalOffsetThreshold: 30};
    const balance = this.state.displayCredit?this.props.feed.credit:this.props.feed.debit;
    const {monthlyLimit, monthlyTotal} = this.props.feed;
    

    let color = theme.COLORS.BLACK;
    if (monthlyLimit && monthlyTotal) color = monthlyTotal>monthlyLimit ? theme.COLORS.SPENDING_HIGH : monthlyTotal<monthlyLimit*0.8 ? theme.COLORS.SPENDING_LOW : theme.COLORS.SPENDING_MODERATE;
    
    return (
      <>
        <Alert></Alert>
        {this.props.feed.data===null ? <Box style={{ alignItems: 'center' }} ><Image source={loadingImage} /></Box>:
        <ScrollView keyboardShouldPersistTaps={'always'} style={{ flex: 1 }} showsVerticalScrollIndicator={false} refreshControl={<RefreshControl refreshing={this.state.refreshing } onRefresh={this._onRefresh}/>}>
          <GestureRecognizer style={{flex: 1}} onSwipe={(direction, state) => this.onSwipe(direction, state)} config={swipeConfig}>
            <Box style={{marginTop:5, marginLeft :'auto',marginRight :'auto',backgroundColor:"#F8F9FA", borderRadius:130, alignItems: 'center', justifyContent: 'center', width:260, height:260}}>
              <Box style={{backgroundColor:"#F3F4F5", borderRadius:105, alignItems: 'center', justifyContent: 'center', width:210, height:210}}>
                <Box style={{backgroundColor:balance<300?"#ED7371":"#78CD83", borderRadius:87.5, alignItems: 'center', justifyContent: 'center',  width:175, height:175}}>
                  <Text style={{fontWeight:'bold', lineHeight:30, fontSize:30, color:'white', textAlign: 'center'}}>{Math.floor(balance).toLocaleString(undefined, { maximumFractionDigits: 0 }) }<Text style={{opacity:0.5}}>.{balance.toFixed(2).toString().split(".")[1]}</Text></Text>
                  <Text style={{color:'white', fontSize:15, textAlign: 'center'}}>{this.state.displayCredit?'CREDIT':'DEBIT'} BALANCE</Text>
                </Box>
              </Box>
            </Box>
          </GestureRecognizer>
          <TouchableOpacity onPress={ ()=>{this.toggleAllowance();}}>
            <Box style={{justifyContent: 'flex-end', marginTop:10, alignItems: 'center', flex:1, flexDirection: 'row'}}>
              <Text style={{}}>Total spent:</Text>
              <Text style={{ paddingLeft:5, paddingRight:5, fontFamily:"roboto-medium", fontSize:21, color}}>${Math.ceil(monthlyTotal)}</Text>
            </Box>
          </TouchableOpacity>
          {this.state.isOpen && <Box style={{paddingRight:10, paddingLeft:10, flexShrink:1, marginTop:10, alignItems: 'center', justifyContent:'flex-end', flexDirection: 'row'}}>
            <Text>{moment(`${this.props.feed.year}-${this.props.feed.month}-01`).format('MMMM')} allowance:</Text>
            <Input type="number-pad" style={{width:100, marginLeft:5, marginRight:5}} placeholder="monthly limit" value={this.props.feed.monthlyLimit}  onChangeText={(value) => this.props.updateAllowance(this.props.feed.year,this.props.feed.month, value)}  />
            {/* <Icon onPress={()=>{ this.props.updateAllowance(this.props.feed.year,this.props.feed.month, /*this.state.mLimit this.props.feed.monthlyLimit); this.toggleAllowance(); }} size={10} style={styles.icon} as={<MaterialCommunityIcons name="content-save" />} color={theme.COLORS.ICON} /> */}
          </Box>}

          <Box style={styles.container}>
            <Box flex="1" style={{ marginBottom: theme.SIZES.BASE }}>              
              <Box flex="1" center style={{ padding: theme.SIZES.BASE }}>
                
                  {this.props.feed.data!==null && this.props.feed.data.map((c,key) => {
                    const {limit} = c.category;
                    const {total} = c.transactions?c.transactions : {total:0};
                    const {note, source, amount, merchantDetails, datetime } = c.transactions && c.transactions.list.length?c.transactions.list[0]:{};
                    return <TouchableOpacity key={key} onPress={()=>this.loadCategory(c)}><SpendingItem amountWidth={this.props.feed.maxAmount.toString().length*6}  totalWidth={this.props.feed.maxTotal.toString().length*10} icon={c.category.icon} note={note} source={source} amount={Math.ceil(amount)} merchant={merchantDetails?merchantDetails.name:''} datetime={datetime} total={total} limit={limit} /></TouchableOpacity>
                  })}

              </Box>
            </Box>
          </Box>
        </ScrollView>}
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    marginTop:-12
  },
  button: {
    marginBottom: 20,
  },
  cards: {
    flex: 1,
    backgroundColor: theme.COLORS.WHITE,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  card: {
    borderWidth: 0,
    backgroundColor: theme.COLORS.WHITE,
    width: width - theme.SIZES.BASE * 2,
    marginVertical: theme.SIZES.BASE * 0.875,
  },
  cardFooter: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginVertical: theme.SIZES.BASE / 2,
    paddingHorizontal: theme.SIZES.BASE,
    paddingVertical: theme.SIZES.BASE / 2,
    backgroundColor: theme.COLORS.TRANSPARENT,
  },
  cardNoRadius: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  cardAvatar: {
    width: theme.SIZES.BASE * 2.5,
    height: theme.SIZES.BASE * 2.5,
    borderRadius: theme.SIZES.BASE * 1.25,
  },
  cardTitle: {
    justifyContent: 'center',
    paddingLeft: theme.SIZES.BASE / 2,
  },
  cardImageContainer: {
    borderWidth: 0,
    overflow: 'hidden',
  },
  cardImageRadius: {
    borderRadius: theme.SIZES.BASE * 0.1875,
  },
  cardImage: {
    width: 'auto',
    height: theme.SIZES.BASE * 12.5,
  },
  cardRounded: {
    borderRadius: theme.SIZES.BASE * 0.5,
  },
  cardFull: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
  },
  cardGradient: {
    bottom: 0,
    left: 0,
    right: 0,
    height: 90,
    position: 'absolute',
    overflow: 'hidden',
    borderBottomRightRadius: theme.SIZES.BASE * 0.5,
    borderBottomLeftRadius: theme.SIZES.BASE * 0.5,
  },
});


const mapStateToProps = (state) => ({
  feed: state.rogers.feed
})

const mapDispatchToProps = {
  loadFeed,
  reloadData,
  selectSpendingCategory,
  updateAllowance
}

export default connect(mapStateToProps, mapDispatchToProps)(Spending)


// const Spending = () => {
//   const balance = this.props.feed.credit;

//   return (
//     <React.Fragment>Meh {balance}</React.Fragment>
//   );
// };

// const mapStateToProps = (state) => ({
//   feed: state.rogers.feed
// })

// const mapDispatchToProps = {
//   loadFeed,
//   selectSpendingCategory,
//   updateAllowance
// }

// export default connect(mapStateToProps, mapDispatchToProps)(Spending)
