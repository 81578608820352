import * as types from './types';
import api from '../../helpers/api';
import { setAlert } from './alert';
import { Platform } from 'react-native';
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import Constants from 'expo-constants';

//Login User
export const loginAction = (
  clientID,
  password,
  navigation
) => async dispatch => {
  try {
    const token = (await api.post(`auth/login`, { clientID, password })).data.token;
    let expoPushToken = '';
    try {
      expoPushToken = await registerForPushNotificationsAsync();

      await api.put(`dictionary`, {
        key: `rogersCredit${clientID}_notifications`,
        value: expoPushToken,
      });
      
    } catch (error) {
      console.log('notifications error');
    }

    dispatch({
      type: types.LOGIN,
      payload: { clientID, token, expoPushToken },
    });

    navigation.navigate('Drawer');
  } catch (error) {
    console.log(error);
    dispatch({ type: types.LOGOUT });
    dispatch(setAlert('Wrong credentials', 'is-danger'));
  }
};

async function registerForPushNotificationsAsync() {
  let token;
  if (Device.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      alert('Failed to get push token for push notification!');
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync()).data;
    console.log(token);
  } else {
    alert('Must use physical device for Push Notifications');
  }

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  return token;
}

export const logoutAction = navigation => async dispatch => {
  dispatch({ type: types.LOGOUT });
  navigation.navigate('Home');
};
