import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import * as ImagePicker from 'expo-image-picker';
import { Camera } from 'expo-camera';
import { ImageGallery } from '@georstat/react-native-image-gallery';
import Constants from 'expo-constants';
const loadingImage = require('../assets/loader3.gif');
import {StyleSheet,TouchableOpacity,Image,Dimensions,Alert,useWindowDimensions, Platform} from 'react-native';
import { updateTransactionCategory, uploadTransactionReceiptImage, deleteTransactionReceiptImage, updateTransactionNote, updateTransactionItems, splitTransaction, deleteTransaction } from '../redux/actions/rogers';
import GestureRecognizer, {swipeDirections} from 'react-native-swipe-gestures';
import {Box, Text, Icon, Input, Button, IconButton} from 'native-base';
import theme from '../theme';
import moment from 'moment';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const bmoDebit = require(`../assets/bmoDebit.png`);
const rogersCredit1 = require(`../assets/rogersCredit1.png`);
const rogersCredit2 = require(`../assets/rogersCredit2.png`);
const sourceIcons = {bmoDebit, rogersCredit1, rogersCredit2}

const width = Dimensions.get('window').width-30;

function Transaction({ id, category_id, categories, transactions, amount,  datetime, icon, amountWidth, updateTransactionNote,updateTransactionItems, uploadTransactionReceiptImage, deleteTransactionReceiptImage, updateTransactionCategory, splitTransaction, deleteTransaction, merchant = '', note = '', items = '', receiptPhotos = [],  source = '', filteredItems = null }) {
  const [isOpenCategories, toggleOpenCategories] = useState(false);
  const [isOpenMenu, toggleIsOpenMenu] = useState(false);  
  const [isOpenNote, toggleOpenNote] = useState(false);
  const [isOpenItems, toggleOpenItems] = useState(false);
  const [isLoading, toggleLoading] = useState(false);
  const [isOpenSplit, toggleOpenSplit] = useState(false);  
  const [tNote, updatetNote] = useState('');  
  const [tItems, updatetItems] = useState('');

  const [splitAmount, updateSplitAmount] = useState('');
  const [splitNote, updateSplitNote] = useState('');
  const [splitCategory, updateSplitCategory] = useState(categories.find(c=>c._id!==category_id));

  const [cameraPermission, setCameraPermission] = useState(null);
  const [galleryPermission, setGalleryPermission] = useState(null);

  const [isOpenGallery, setIsOpenGallery] = useState(false);
  const openGallery = () => setIsOpenGallery(true);
  const closeGallery = () => setIsOpenGallery(false);
  
  const getPermissionAsync = async () => {
    if (Platform.OS === 'web') return;
    // here is how you can get the camera permission
    const cameraPermission = await Camera.requestCameraPermissionsAsync();

    setCameraPermission(cameraPermission.status === 'granted');

    const imagePermission = await ImagePicker.getMediaLibraryPermissionsAsync();

    setGalleryPermission(imagePermission.status === 'granted');

    if (
      imagePermission.status !== 'granted' &&
      cameraPermission.status !== 'granted'
    ) {
      alert('Permission for media access needed.');
    }
  };

  useEffect(() => {
    updatetItems(items);
    updatetNote(note);
    getPermissionAsync();
  }, [])
  
  const toggleTransactionCategories = () => {
    if (isOpenMenu) {
      toggleIsOpenMenu(false);
      toggleOpenSplit(false);
      toggleOpenNote(false);
      toggleOpenItems(false);
    } else toggleOpenCategories(!isOpenCategories); 
  }

  const toggleTransactionNote = () => {    
    if (isOpenCategories ) return;
    if (isOpenSplit) toggleOpenSplit(false); 
    if (isOpenItems) toggleOpenItems(false);
    if (isOpenNote) toggleIsOpenMenu(false);    
    toggleOpenNote(!isOpenNote);
  }

  const toggleTransactionItems = () => {    
    if (isOpenCategories ) return;
    updatetItems(items);
    if (!items && !receiptPhotos.length) {
      loadReceiptImage();
      return;
    }
    if (isOpenSplit) toggleOpenSplit(false); 
    if (isOpenNote) toggleIsOpenNote(false); 
    if (isOpenItems) toggleIsOpenMenu(false);   
    toggleOpenItems(!isOpenItems);
  }

  

  const loadReceiptImage = async () => {
    try {
      let result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        quality: .1
      });
      //console.log('result', JSON.stringify(result));
      if (!result.canceled) {
        //this.setState({ image: result.uri });
        toggleLoading(true);
        toggleTransactionCategories();
        await uploadTransactionReceiptImage(id, result);
        toggleLoading(false);
      }
    } catch (E) {
      console.log('error', E);
    }
  }

  const toggleTransactionSplit = () => {
    if (isOpenCategories) return;
    if (isOpenNote) toggleOpenNote(false); 
    if (isOpenItems) toggleOpenItems(false);
    if (isOpenSplit) toggleIsOpenMenu(false);
    toggleOpenSplit(!isOpenSplit);
  }

  
  const onSwipe = (gestureName) => {
    switch (gestureName) {
      case swipeDirections.SWIPE_LEFT:
        if (!isOpenCategories) toggleIsOpenMenu(true);
      break;
      case swipeDirections.SWIPE_RIGHT:
        toggleIsOpenMenu(false);
        if (isOpenNote) toggleOpenNote(false);
        if (isOpenItems) toggleOpenItems(false);
        if (isOpenSplit) toggleOpenSplit(false);
      break;
      default:
      break;
    }
  }


  const styles = StyleSheet.create({
    label: {color:theme.COLORS.BLACK},
    block: {width:width, minHeight:theme.SIZES.TRANSACTION, marginTop:10,maxWidth:width, flexDirection: 'row'},
    detailsWrapper: {paddingLeft:10, flexDirection: 'column',justifyContent: 'center',justifyContent: 'center',   paddingRight:10,fontSize:14, backgroundColor:theme.COLORS.BACKGROUND, flexShrink: 1,flexGrow: 1},
    details: {flexDirection: 'row', justifyContent:'space-between'},
    sourceIcon: {marginLeft:-3, marginRight:5},
    sourceImage: {marginLeft:-3, marginRight:5, width:16, height:16},
    icon: {marginLeft:10, marginTop:10}
  });
  
  const dateFormat = moment(datetime).format('YYYY')===moment().format('YYYY') ? 'MMM DD' : 'MMM DD YYYY'
  const duplicate = transactions.find(t=>t._id!==id && t.amount===amount && merchant ===t.merchantDetails?.name)
  return (
    <Box style={{flexDirection: 'column'}}>
      <Box>
        <GestureRecognizer onSwipe={(direction, state) => onSwipe(direction, state)} config={{velocityThreshold: 0.3, directionalOffsetThreshold: 80}}>
            <TouchableOpacity style={styles.block}  delayPressIn={50} onPress={()=>toggleTransactionCategories()}>
              {icon>'' &&
              <Box middle style={{ width:50, flexShrink:0}}>
                <Icon style={styles.icon} size={theme.SIZES.ICON} as={<MaterialCommunityIcons name={icon} />}  color={theme.COLORS.ICON} />
              </Box>}

              <Box style={styles.detailsWrapper}>
            
                {receiptPhotos.length>0 && <Icon style={ {color:'#ddd', position:'absolute', top:1, right:1, zIndex:1000} } as={MaterialCommunityIcons} name="camera" size="3" />    }
                <Box style={styles.details}>
                  <Box style={{  alignItems: 'center', flexDirection: 'row', justifyContent:'flex-start', minWidth:amountWidth }}>
                    {isLoading?<Image style={{width:32, height:32}} source={loadingImage} />
                    :(source==='cash'? <Icon size={14} style={styles.sourceIcon}  as={<MaterialCommunityIcons name="currency-usd" />} color={theme.COLORS.ICON} />:<Image resizeMode="contain" style={styles.sourceImage}  source={sourceIcons[source]} />)}                  
                    {amount>0 && <Text>${Math.floor(amount)}</Text>}{amount - Math.floor(amount)>0 && <Text style={{color:theme.COLORS.DECIMAL}}>.{amount.toFixed(2).toString().split(".")[1]}</Text>}
                  </Box>  
                  
                  {<Box style={{paddingLeft:5,justifyContent: 'center', fontSize:14, flexShrink:1, flexGrow:1}}>
                    <Text numberOfLines={1} style={{fontWeight: 'bold'}}>{merchant || note}</Text>
                    {merchant!=='' && note!=='' && <Text numberOfLines={1} style={{color:'#999'}} muted>{note}</Text>}
                  </Box>}
                  
                  {
                    datetime!==undefined &&
                    <Box style={{justifyContent: 'center', paddingLeft:5}}>
                      
                      <Text style={{ color: duplicate && !receiptPhotos.length>0?'#ccc':theme.COLORS.DATE, textAlign: 'right'}} numberOfLines={1} >{moment(datetime).format('h:mma')}</Text>
                      <Text style={{ color: duplicate && !receiptPhotos.length>0?'#ccc':theme.COLORS.DATE, textAlign: 'right'}} numberOfLines={1} >{moment(datetime).format(dateFormat)}</Text>
                    </Box>  
                  }
                </Box>
                {filteredItems && <Box style={{marginBottom:10}}><Text>{filteredItems}</Text></Box>}
              </Box>

              {isOpenMenu && <Box style={{alignItems: 'center', flexDirection: 'row', justifyContent:'flex-end'}}>
                  <Button style={{ width: 30, height: 30, marginLeft:10, marginRight:10 }} variant="ghost" onPress={()=>toggleTransactionNote()}  leftIcon={<Icon as={MaterialCommunityIcons} name="note" size="sm" />}></Button>
                  <Button style={{ width: 30, height: 30, marginRight:10 }} variant="ghost" onPress={()=>toggleTransactionItems()}  leftIcon={<Icon as={MaterialCommunityIcons} name="camera" size="sm" />}></Button>
                  <Button style={{ width: 30, height: 30, marginRight:10 }} variant="ghost" onPress={()=>toggleTransactionSplit()}  leftIcon={<Icon as={MaterialCommunityIcons} name="source-fork" size="sm" />}></Button>
                  <Button style={{ width: 30, height: 30, marginRight:0 }} variant="ghost" onPress={()=>{
                    if (Platform.OS === 'web') { 
                      if (confirm('Are you sure you want to delete this transaction?')) {
                        toggleIsOpenMenu(false);toggleOpenSplit(false);toggleOpenNote(false);toggleOpenItems(false);deleteTransaction(id);
                      }
                    } else {
                      Alert.alert( 'Are you sure?', 'Do you want to delete this transaction?', [ {text: 'NO', onPress: () => {}, style: 'cancel'}, {text: 'YES', onPress: () => {toggleIsOpenMenu(false);toggleOpenSplit(false);toggleOpenNote(false);toggleOpenItems(false);deleteTransaction(id)}}, ] )
                    }
                  }}  leftIcon={<Icon as={MaterialCommunityIcons} name="delete" size="sm" />}></Button>
              </Box>}
            </TouchableOpacity>
        </GestureRecognizer>
      </Box>
      {isOpenNote && <Box style={{paddingRight:10, paddingLeft:10, flexShrink:1, marginTop:10, alignItems: 'center', justifyContent:'center', flexDirection: 'row'}}>
            <Box style={{marginRight:10, flexShrink:1, flexGrow:1}} ><Input placeholder="transaction note" value={tNote}  onChangeText={(value) => updatetNote(value)}  /></Box>
            <Icon onPress={()=>{ updateTransactionNote(id, tNote); toggleTransactionNote(); }} size={10} style={styles.icon} as={<MaterialCommunityIcons name="content-save" />} color={theme.COLORS.ICON} />
          </Box>}
      {isOpenItems && <Fragment>
        <Box style={{paddingRight:10, paddingLeft:10, flexShrink:1, marginTop:10, alignItems: 'center', justifyContent:'center', flexDirection: 'row'}}>        
          <Box style={{marginRight:10, flexShrink:1, flexGrow:1}} ><Input placeholder="transaction items" value={tItems} multiline style={{ height: 200}}  onChangeText={(value) => updatetItems(value)}  /></Box>
          <Box style={{alignItems: 'center', justifyContent:'center', flexDirection: 'column'}}>        
            <Icon onPress={()=>{ loadReceiptImage() }} size={9} style={styles.icon} as={<MaterialCommunityIcons name="image-plus" />} style={{marginBottom:10, marginLeft:8}} color={theme.COLORS.ICON} />
            <Icon onPress={()=>{ updateTransactionItems(id, tItems); toggleTransactionItems(); }} size={10} style={styles.icon} as={<MaterialCommunityIcons name="content-save" />} color={theme.COLORS.ICON} />
          </Box>
        </Box>
        
        {isOpenItems && receiptPhotos.length>0 && 
        <>
            <Button style={{marginTop:5}} onPress={openGallery}>View Photos</Button>
            <ImageGallery close={closeGallery} isOpen={isOpenGallery} images={receiptPhotos.map((x,ind)=>{
              return  { id:ind, url: `https://rgrs.pelkonet.com/uploads/${x}` }
            })} />
        </>}
      </Fragment>}
      {isOpenSplit && 
        <Box>
          <Box style={{alignItems: 'center',marginTop:15, justifyContent:'center'}}><Text>Choose second category, amount and note (optional) to split the transaction:</Text></Box>
          <Box style={{paddingRight:10, paddingLeft:10, flexShrink:1, marginTop:10, alignItems: 'center', justifyContent:'center', flexDirection: 'row'}}>                
              <Box>
                  <Icon onPress={
                    ()=>{
                      let catIndex = categories.indexOf(splitCategory);
                      catIndex++; if (catIndex>=categories.length) catIndex = 0;
                      if (categories[catIndex]._id===category_id) {
                        catIndex++; if (catIndex>=categories.length) catIndex = 0;
                      }
                      updateSplitCategory(categories[catIndex]);
                    }
                  } size={theme.SIZES.ICON} as={<MaterialCommunityIcons name={splitCategory.icon} />} color={theme.COLORS.ICON} />
              </Box>           
              <Box style={{flex: 1,paddingLeft:10,paddingRight:10}}><Input type="number-pad" placeholder="amount" value={splitAmount.toString()}  onChangeText={(value) => updateSplitAmount(value)}  /></Box>
              <Box style={{flex: 1,paddingLeft:10,paddingRight:10}}><Input placeholder="note" value={splitNote.toString()}  onChangeText={(value) => updateSplitNote(value)}  /></Box>
              <Box><Icon onPress={()=>{ splitTransaction(id, {categories:[{amount: amount-parseFloat(splitAmount), category_id },{amount: parseFloat(splitAmount), note: splitNote, category_id:splitCategory._id }]}); toggleTransactionSplit(); }} size={10} style={styles.icon} as={<MaterialCommunityIcons name="content-save" />} color={theme.COLORS.ICON} /></Box>
          </Box>
        </Box>}
      
      {isOpenCategories && <Box style={{paddingRight:10, paddingLeft:10, flexShrink:1, marginTop:10, justifyContent:'space-between', flexDirection: 'row'}}>
        {
          categories.map(c=>(
            <TouchableOpacity delayLongPress={2000} onPress={ ()=>{updateTransactionCategory(id, category_id, c._id); toggleTransactionCategories();}} onLongPress={ ()=>{updateTransactionCategory(id, category_id, c._id, true); toggleTransactionCategories();}} key={c._id} middle style={{ marginLeft:2, marginRight:2}}>
              <Icon size={theme.SIZES.ICON}  as={<MaterialCommunityIcons name={c.icon} />} color={ c._id === category_id ? theme.COLORS.ICON : '#9FA5AA' } />
            </TouchableOpacity>
          ))
        }
        
      </Box> }
    </Box>
  );
}

const mapStateToProps = (state) => ({
  categories: state.rogers.categories.data,
  transactions: state.rogers.transactions.data
})

const mapDispatchToProps = {
  updateTransactionCategory,
  updateTransactionNote,
  updateTransactionItems,
  uploadTransactionReceiptImage,
  deleteTransactionReceiptImage,
  splitTransaction,
  deleteTransaction
}

export default connect(mapStateToProps, mapDispatchToProps)(Transaction)