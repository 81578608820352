const COLORS = {
    WHITE: '#FFFFFF',
    BLACK: '#414042',
    GREY: '#898989',
    THEME: '#DA2B1C',
    PRIMARY: '#DA2B1C',
    INFO: '#1232FF',
    ERROR: '#FE2472',
    WARNING: '#FF9C09',
    SUCCESS: '#00AEC7',
    MUTEDBUTTON: '#9FA5AA',
    TRANSPARENT: 'transparent',
    INPUT: '#808080',
    PLACEHOLDER: '#9FA5AA',
    NAVBAR: '#F9F9F9',
    BLOCK: '#808080',
    MUTED: '#9FA5AA',
    NEUTRAL: 'rgba(255,255,255, 0.65)',
    FACEBOOK: '#3B5998',
    TWITTER: '#5BC0DE',
    DRIBBBLE: '#EA4C89',
    ICON: '#414042',
    SPENDING_LOW: '#78CD83',
    SPENDING_MODERATE: '#FFBF3F',
    SPENDING_HIGH: '#DA291C',
    BACKGROUND: '#EBEBEB',
    DATE: '#868b8f',
    DECIMAL: '#5c5959'
  };
  
  const SIZES = {
    BASE: 16,
    FONT: 16,
    TRANSACTION:50,
    ICON:28,
    OPACITY: 0.8,
    BUTTON_WIDTH:10,
    NAVBAR_HEIGHT:1,
    NAVBAR_VERTICAL:1
  
  };
  
  export default {
    COLORS,
    SIZES,
  };
  